import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HamburgerMenu.css';

function HamburgerMenu({ toggleMenu, isOpen }) {
  const menuRef = useRef(null);

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        toggleMenu();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleMenu]);

  return (
    <div ref={menuRef} className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
      <span className="close-icon" onClick={toggleMenu}>
        &times;
      </span>
      <Link to="/" onClick={toggleMenu}>Home</Link>
      <Link to="/about" onClick={toggleMenu}>About</Link>
      <Link to="/resume" onClick={toggleMenu}>Resume</Link>
      <Link to="/contact" onClick={toggleMenu}>Contact</Link>
    </div>
  );
}

export default HamburgerMenu;
