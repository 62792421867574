import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import CanonicalURL from './components/CanonicalURL';
import './App.css';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Helmet>
          {/* Global Metadata */}
          <title>Sojol Rana - Portfolio & Blog</title>
          <meta name="description" content="Sojol Rana's personal portfolio showcasing programming skills, blog posts, and software development projects." />
          <meta name="keywords" content="portfolio, Sojol Rana, web development, AI, machine learning, software engineer, blog" />
          <meta name="author" content="Sojol Rana" />
          <meta name="robots" content="index, follow" />

          {/* Open Graph meta tags for social sharing */}
          <meta property="og:title" content="Sojol Rana - Portfolio & Blog" />
          <meta property="og:description" content="Visit my portfolio showcasing my work and projects on AI, web development, and more." />
          <meta property="og:image" content="https://sojolrana.com/images/og-image.jpg" />
          <meta property="og:url" content="https://sojolrana.com" />
          <meta property="og:type" content="website" />
          
          {/* Twitter Card meta tags for Twitter sharing */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Sojol Rana - Portfolio & Blog" />
          <meta name="twitter:description" content="Explore my portfolio to learn about my work and blog on web development and AI." />
          <meta name="twitter:image" content="https://sojolrana.com/images/og-image.jpg" />
        </Helmet>

        <CanonicalURL />

        <Header />
        <main className="main-content">
          <Breadcrumbs />
          <PageTitle />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

function Breadcrumbs() {
  const location = useLocation();
  const pathnameParts = location.pathname.split('/').filter((part) => part);

  // Build the breadcrumb structured data
  const breadcrumbItems = pathnameParts.map((part, index) => ({
    "@type": "ListItem",
    "position": index + 1,
    "name": part.charAt(0).toUpperCase() + part.slice(1),
    "item": `${window.location.origin}/${pathnameParts.slice(0, index + 1).join('/')}`
  }));

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": `${window.location.origin}/`
      },
      ...breadcrumbItems
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbSchema)}
      </script>
    </Helmet>
  );
}

// Set title based on route
const PageTitle = () => {
  const location = useLocation();
  let title = 'Sojol Rana';  // Default title

  if (location.pathname === '/') {
    title = 'Sojol Rana';  // Home page title
  } else if (location.pathname === '/about') {
    title = 'About';
  } else if (location.pathname === '/resume') {
    title = 'Resume';
  } else if (location.pathname === '/contact') {
    title = 'Contact';
  }
  else {
    title = 'Not found!';
  }

  return (
    <Helmet>
      <title>{location.pathname === '/' ? 'Sojol Rana' : `${title} | Sojol Rana`}</title>
    </Helmet>
  );
};
export default App;
