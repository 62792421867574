import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-page">
      <div className="ide-container">
        <p>
{`Hello, my name is Sojol Rana, a Computer Science and Engineering student at Dhaka University of Engineering & Technology (DUET), Gazipur. I am passionate about AI and Machine Learning, with strong skills in problem-solving, analytical thinking, and proficiency in C and C++. As the 1st runners-up in the Intra DUET programming contest, I've honed both my coding and teamwork abilities. I am eager to deepen my understanding of real-world AI applications and contribute to innovative projects.`}
        </p>
      </div>
    </div>
  );
}

export default About;
