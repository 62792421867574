import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from './HamburgerMenu';
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="header">
      <div className="header-left">
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
        <h1 className="logo">
          <Link to="/">Sojol Rana</Link>
        </h1>
      </div>
      <nav className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/resume">Resume</Link>
        <Link to="/contact">Contact</Link>
      </nav>
      <HamburgerMenu toggleMenu={toggleMenu} isOpen={isOpen} />
    </header>
  );
}

export default Header;
