import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="top-footer">
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>
      <div className="bottom-footer">
        &copy; {new Date().getFullYear()} Sojol Rana | All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;
